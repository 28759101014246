import React from "react"
import { Box, Image } from "@chakra-ui/core"
import SEO from "../components/SEO"
import TeacherExamImage from "../../images/seminarImage.jpeg"

const TeacherExamSeminar = (): JSX.Element => {
  return (
    <Box>
      <SEO title="ஆசிரியர் தேர்வு இலவச வெற்றி கருத்தரங்கம்" />
      <Box maxW="800px" mx="auto">
        <Image w="100%" src={TeacherExamImage} />
      </Box>
    </Box>
  )
}

export default TeacherExamSeminar
